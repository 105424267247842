$(document).ready(function () {
  "use strict";

  if ($("#activity-feed").length) {
    //Feed v1 left menu
    if ($(".feed-menu-v1").length) {
      $(".feed-menu-v1 .main-menu li.is-active").find(".submenu").slideDown();
      $(".feed-menu-v1 .main-menu li").on("click", function () {
        //$('.submenu').slideUp();
        $(this)
          .siblings("li")
          .removeClass("is-active")
          .find(".submenu")
          .slideUp();
        $(this).addClass("is-active").find(".submenu").slideDown();
      });
    }

    //Open publish mode
    // $("#publish").on("click", function () {
    //   $(".app-overlay").addClass("is-active");
    //   $(".is-new-content").addClass("is-highlighted");
    // });

    //Open publish mode from new story-button
    // $("#add-story-button").on("click", function () {
    //   $(".app-overlay").addClass("is-active");
    //   $(".is-new-content").addClass("is-highlighted");
    //   $(".target-channels .channel").each(function () {
    //     if ($(this).find('input[type="checkbox"]').prop("checked")) {
    //       $(this).find('input[type="checkbox"]').prop("checked", false);
    //     } else {
    //       $(this).find('input[type="checkbox"]').prop("checked", true);
    //     }
    //   });
    // });

    //Enable and disable publish button based on the textarea value length (1)
    // $("#publish").on("input", function () {
    //   var valueLength = $(this).val().length;

    //   if (valueLength >= 1) {
    //     $("#publish-button").removeClass("is-disabled");
    //   } else {
    //     $("#publish-button").addClass("is-disabled");
    //   }
    // });

    //Close compose box
    $(".close-publish").on("click", function () {
      $(".app-overlay").removeClass("is-active");
      $(".is-new-content").removeClass("is-highlighted");
      $("#compose-search, #extended-options, .is-suboption").addClass(
        "is-hidden"
      );
      $("#basic-options, #open-compose-search").removeClass("is-hidden");
    });

    //Expand compose box
    $("#show-compose-friends").on("click", function () {
      $(this).addClass("is-hidden");
      $(".friends-list").removeClass("is-hidden");
      $(".hidden-options").addClass("is-opened");
    });

    //Open extended options
    $("#open-extended-options").on("click", function () {
      $(".app-overlay").addClass("is-active");
      $(".is-new-content").addClass("is-highlighted");

      // $(".compose-options").toggleClass("is-hidden");
      $(".compose-options").removeClass("is-hidden");
      $("#basic-options").addClass("is-hidden");
    });

    //Open compose box search
    $("#open-compose-search").on("click", function () {
      $("#compose-search, #open-compose-search").toggleClass("is-hidden");
    });

    //Enable checkbox checking and unchecking by clicking on the row
    // $(".channel, .friend-block").on("click", function (e) {
    //   if (e.target !== this) {
    //     return false;
    //   } else {
    //     if ($(this).find('input[type="checkbox"]').prop("checked")) {
    //       $(this).find('input[type="checkbox"]').prop("checked", false);
    //     } else {
    //       $(this).find('input[type="checkbox"]').prop("checked", true);
    //     }
    //   }
    // });
    // $(".friend-block").on("click", function (e) {
    //   if (e.target !== this) {
    //     return false;
    //   } else {
    //     if ($(this).find('input[type="checkbox"]').prop("checked")) {
    //       $(this).find('input[type="checkbox"]').prop("checked", false);
    //     } else {
    //       $(this).find('input[type="checkbox"]').prop("checked", true);
    //     }
    //   }
    // });

    //Suboptions
    $("#open-tag-suboption").on("click", function () {
      $(".is-suboption").addClass("is-hidden");
      $("#tag-suboption").removeClass("is-hidden");
      //Open autocomplete dropdown
      openFriendsDrop();
    });

    //Show activities
    $("#show-activities, #extended-show-activities").on("click", function () {
      $(".app-overlay").addClass("is-active");
      $(".is-new-content").addClass("is-highlighted");
      //$('.compose-options').toggleClass('is-hidden');
      $(".is-suboption").addClass("is-hidden");
      $("#activities-suboption").removeClass("is-hidden");
      //Open autocomplete dropdown
      openActivitiesDrop();
    });

    //
    $(".input-block, .close-icon.is-subactivity").on("click", function () {
      $("#activities-autocpl-wrapper").toggleClass("is-hidden");
      $(".is-activity").addClass("is-hidden");
      $(".easy-autocomplete-container li").removeClass("selected");
      //$(".mood-display").html("");
      //Open autocomplete dropdown
      openActivitiesDrop();
    });

    //Show location input
    $("#open-location-suboption").on("click", function () {
      $(".is-suboption").addClass("is-hidden");
      $("#location-suboption").removeClass("is-hidden");
    });

    //Show URL input
    $("#open-link-suboption").on("click", function () {
      $(".is-suboption").addClass("is-hidden");
      $("#link-suboption").removeClass("is-hidden").find("input").focus();
    });
    //hide URL input
    $("#link-autocpl").on("keyup", function (e) {
      if (e.key == "Enter" || e.keyCode === 13) {
        $("#link-suboption").addClass("is-hidden");
      }
    });

    //Show GIF input
    $("#open-gif-suboption").on("click", function () {
      $(".is-suboption").addClass("is-hidden");
      $("#gif-suboption").removeClass("is-hidden");
    });

    //Close autocomplete sections when clicking on the X
    $(".is-autocomplete .close-icon.is-main").on("click", function () {
      $(this).closest(".is-suboption").addClass("is-hidden");
    });

    //Init comments
    //initPostComments();

    //Handle adding member in a new group (modal)
    // $("#new-group-list .friend-block").on("click", function () {
    //   var selectedRef = $(this).closest(".friend-block").attr("data-ref");
    //   var selectedAvatar = $(this)
    //     .closest(".friend-block")
    //     .find("img")
    //     .attr("src");
    //   var selectedFriend = $(this)
    //     .closest(".friend-block")
    //     .find(".friend-name")
    //     .text();
    //   var checkIcon = feather.icons.check.toSvg();

    //   var html = "";

    //   if ($(this).find("input").prop("checked")) {
    //     if ($("#" + selectedRef).length) {
    //       return false;
    //     } else {
    //       html = `
    //                     <div id="${selectedRef}" class="selected-friend-block">
    //                         <div class="image-wrapper">
    //                             <img class="friend-avatar" src="${selectedAvatar}" alt="">
    //                             <div class="checked-badge">
    //                                 ${checkIcon}
    //                             </div>
    //                         </div>
    //                         <div class="friend-name">${selectedFriend}</div>
    //                     </div>
    //                 `;
    //       $("#selected-list").append(html);

    //       var selectedCount = $("#selected-list .selected-friend-block").length;
    //       $("#selected-friends-count").html(selectedCount);
    //     }
    //   } else {
    //     console.log("it has been unchecked!");
    //     $("#" + selectedRef).remove();

    //     var selectedCount = $("#selected-list .selected-friend-block").length;
    //     $("#selected-friends-count").html(selectedCount);
    //   }
    // });

    //Help modal before albums management
    albumsHelp();

    function albumsHelp() {
      $("#albums-help-modal .next-modal").one("click", function () {
        $(this)
          .closest(".card-body")
          .find(".content-block, .dot")
          .toggleClass("is-active");
        $(this).text("got it").off();
        endAlbumHelp();
      });
    }

    function endAlbumHelp() {
      $("#albums-help-modal .next-modal").on("click", function () {
        var $this = $(this);
        var albumsModal = $this.attr("data-modal");
        $this.closest(".modal").removeClass("is-active");
        $("#" + albumsModal).addClass("is-active");
        setTimeout(function () {
          $this
            .closest(".card-body")
            .find(".content-block, .dot")
            .toggleClass("is-active");
          $this.text("Next").off();
          albumsHelp();
        }, 800);
      });
    }

    //Toggle tag friends input in album modal
    $("#tagged-in-album button").on("click", function () {
      $(this).addClass("is-hidden");
      $(this)
        .closest(".tagged-in-album")
        .find(".field, p")
        .toggleClass("is-hidden");
    });

    //Toggle datepicker input in album modal
    $("#album-date button").on("click", function () {
      $(this).addClass("is-hidden");
      $(this).closest(".album-date").find("p").addClass("is-hidden");
      $(this).closest(".album-date").find(".control").removeClass("is-hidden");
    });

    //Init datepicker inside album modal
    // $("#album-datepicker").datepicker({
    //   format: "mm-dd-yyyy",
    //   container: "body",
    //   autoHide: true,
    //   offset: 0,
    // });

    //Help modal before live video
    videosHelp();

    function videosHelp() {
      $("#videos-help-modal .next-modal").one("click", function () {
        $(this)
          .closest(".card-body")
          .find(".content-block, .dot")
          .toggleClass("is-active");
        $(this).text("got it").off();
        endVideoHelp();
      });
    }

    function endVideoHelp() {
      $("#videos-help-modal .next-modal").on("click", function () {
        var $this = $(this);
        var videosModal = $(this).attr("data-modal");
        $this.closest(".modal").removeClass("is-active");
        if (window.matchMedia("(orientation: portrait)").matches) {
          $("#no-stream-modal").addClass("is-active");
        } else {
          $("#" + videosModal).addClass("is-active");
        }
        setTimeout(function () {
          $this
            .closest(".card-body")
            .find(".content-block, .dot")
            .toggleClass("is-active");
          $this.text("Next").off();
          videosHelp();
        }, 800);
      });
    } //Add a recommended page to favorite
  }

  if ($("#share-modal").length) {
    //Share modal main dropdown
    $(".share-dropdown").on("click", function () {
      $(this).toggleClass("is-active");
    });

    //Share modal main dropdown
    $(".share-dropdown .dropdown-item").on("click", function () {
      var targetSharingChannel = $(this).attr("data-target-channel");
      var channelIcon = $(this).find("i").attr("class");
      var channelName = $(this).find("h3").text();

      if (targetSharingChannel !== undefined) {
        $(".share-dropdown .button")
          .find("i")
          .removeClass()
          .addClass(channelIcon);
        $(".share-dropdown .button").find("span").text(channelName);
        $(".share-channel-control").addClass("is-hidden");
        $(".footer-action.is-active").removeClass("is-active");
        $("#share-to-" + targetSharingChannel)
          .removeClass("is-hidden")
          .find("input")
          .focus();
      }
    });

    //Share modal page selector subdropdown
    $(".page-dropdown").on("click", function () {
      $(this).toggleClass("is-active");
    });

    //Share modal footer actions
    $(".action-wrap .footer-action").on("click", function () {
      var targetAction = $(this).attr("data-target-action");

      $(".footer-action.is-active").removeClass("is-active");
      $(this).addClass("is-active");

      if (targetAction !== undefined) {
        //$('.share-channel-control').addClass('is-hidden');
        $(".bottom-share-inputs .field").addClass("is-hidden");
        $("#action-" + targetAction)
          .removeClass("is-hidden")
          .find("input")
          .focus();
      }
    });
  }

  if ($(".feed-slider-wrapper").length) {
    $(".feed-slider-inner").slick({
      centerMode: true,
      centerPadding: "10px",
      slidesToShow: 3,
      prevArrow:
        "<div class='slick-custom is-prev'><i class='mdi mdi-chevron-left'></i></div>",
      nextArrow:
        "<div class='slick-custom is-next'><i class='mdi mdi-chevron-right'></i></div>",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    });
  }
});
