$(document).ready(function () {
	"use strict";

	//Close chat app
	// $(".close-chat, .open-chat").on("click", function () {
	// 	$(".chat-wrapper").toggleClass("is-active");
	// 	$("body").toggleClass("is-frozen");
	// });

	//Close chat side panel
	$("#chat-panel .panel-close").on("click", function () {
		$("#chat-body, #chat-panel").removeClass("is-opened");
	});
});
